import React, { createElement } from 'react';
import { StyledHeader, StyledHeaderContent } from './styles';
import { IHeader } from './Header.types';

const BaseHeader = ({ level, children }: IHeader) => {
  return createElement(
    `h${level}`,
    {
      'data-testid': 'header',
    },
    children
  );
};

const Header = ({
  children,
  color,
  subColor,
  fontSize,
  lineHeight,
  fontWeight,
  fontFamily,
  dataTestId,
  level,
  subFontWeight,
  centered,
  style,
}: IHeader) => {
  return (
    <StyledHeader>
      <BaseHeader level={level || 1}>
        <StyledHeaderContent
          color={color}
          subColor={subColor}
          fontSize={fontSize}
          lineHeight={lineHeight}
          fontWeight={fontWeight}
          fontFamily={fontFamily}
          data-testid={dataTestId}
          subFontWeight={subFontWeight}
          centered={centered}
          style={style}
        >
          {children}
        </StyledHeaderContent>
      </BaseHeader>
    </StyledHeader>
  );
};

export default Header;
