import styled from 'styled-components';
import { primaryColors } from '../../colors';
import { px2Rem } from '../../utils/px2Rem';
import { CSS_FONT_WEIGHT } from '../../constants';

export const AccountHeader = styled.header`
  padding: 0 ${px2Rem(35)};
  background-color: ${primaryColors.b01};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @media (max-width: 1200px) {
    padding: 0 ${px2Rem(20)};
  }
`;

export const AccountHeaderNav = styled.nav<{ $visible: boolean }>`
  @media (max-width: 900px) {
    position: absolute;
    z-index: 5;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    overflow: hidden;
    max-height: ${({ $visible }) => $visible ? '700px' : '0'};
    background-color: ${primaryColors.b01};
    transition: max-height .65s ease-in-out;
  }

  ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;

    @media (max-width: 900px) {
      display: block;
      padding-left: ${px2Rem(20)};
    }
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(-40px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  li {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
    animation: ${({ $visible }) => $visible ? 'fadeInUp 0.6s ease-in' : 'none'};

    &.active, &:hover {
      a, button {
        position: relative;
        height: (100% - ${px2Rem(4)});
        display: block;
        width: fit-content;
        font-weight: ${CSS_FONT_WEIGHT.vars.bold};

          &::after {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            right: 0;
            height: ${px2Rem(4)};
            background-color: ${primaryColors.p01};
          }
      }
    }

    &:not(:last-of-type) {
      margin-right: ${px2Rem(40)};

      @media (max-width: 1200px) {
        margin-right: ${px2Rem(32)};
      }

      @media (max-width: 900px) {
        margin-right: 0;
      }
    }

    @media (max-width: 900px) {
      display: block;
      margin-bottom: ${px2Rem(20)};
      padding-left: 0;
    }
  }
`;

export const NavLink = styled.a`
  font-size: 0.875em;
  font-weight: ${CSS_FONT_WEIGHT.vars.regular};
  color: ${primaryColors.w01};
  letter-spacing: ${px2Rem(0.9)};
  line-height: 4.14;
  text-transform: uppercase;
  text-decoration: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
`;

export const NavToggle = styled.div`
  width: ${px2Rem(40)};
  height: ${px2Rem(40)};
  padding: ${px2Rem(10)};
  border-radius: ${px2Rem(1)};
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NavToggleIcon = styled.div<{ $active: boolean }>`
  ${({ $active }) => `
    display: inline-block;
    vertical-align: super;

    span,
    span:before,
    span:after {
      cursor: pointer;
      height: ${px2Rem(2)};
      width: ${px2Rem(20)};
      background: ${primaryColors.w01};
      position: absolute;
      display: block;
      content: '';
      transition: all 0.35s;
    }

    span:before {
      top: ${$active ? '0' : '-6px'};
      transform: ${$active ? 'rotate(45deg)' : 'none'};
    }

    span:after {
      bottom: ${$active ? 'unset' : '-6px'};
      top: ${$active ? '6px' : 'unset'};
      transform: ${$active ? 'translateY(-6px) rotate(-45deg)' : 'none'};
    }

    span {
      background-color: ${$active ? 'transparent' : primaryColors.w01};
    }
  `}
`;
