import { IStyledGrid } from './../grid/types';
import { px2Rem } from './../../utils/px2Rem';
import styled from 'styled-components';

const StyledGrid = styled.div<IStyledGrid>`
  ${({ columns, autofillWidth, rows, rowGap, columnGap, alignCenter, columnsTablet, columnsMobile }) => `
    display: grid;
    grid-template-columns: ${autofillWidth ? `repeat(auto-fill, ${autofillWidth})` : `repeat(${columns}, 1fr)`};
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    ${rows && `grid-template-rows: repeat(${rows}, 1fr)`};
    ${rowGap && `grid-row-gap: ${px2Rem(rowGap)}`};
    ${columnGap && `grid-column-gap: ${px2Rem(columnGap)}`};
    ${alignCenter && 'align-items: center'};

    @media (max-width: 996px) {
      ${columnsTablet && `grid-template-columns: repeat(${columnsTablet}, 1fr)`};
    }

    @media (max-width: 600px) {
      ${columnsMobile && `grid-template-columns: repeat(${columnsMobile}, 1fr)`};
    }
  `}
`;

export { StyledGrid };
