import { primaryColors } from './../../styles/variables/colours';
import styled, { css } from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';
import NEW_BREAKPOINTS from '@/constants/breakpoints';
import { Link } from '../../elements/Link/Link';

export const StyledNavBar = styled.nav`
  min-height: ${px2Rem(72)};
  background-color: ${({ theme }) => theme?.navBar?.backgroundColor};
  border-bottom: ${({ theme }) => theme?.navBar?.bottomBorder};
  z-index: 1000;

  @media (min-width: ${NEW_BREAKPOINTS.mobileNav}px) {
    height: ${px2Rem(78)};
  }
`;

export const StyledNavBarInner = styled.div<{
  $desktopNoRightPadding: boolean;
}>`
  display: flex;
  flex-direction: column;

  min-height: inherit;
  padding: 0 ${px2Rem(24)};

  @media (min-width: ${NEW_BREAKPOINTS.mobileNav}px) {
    flex-direction: row;
    padding: 0
      ${({ $desktopNoRightPadding }) =>
        $desktopNoRightPadding ? '0' : px2Rem(20)}
      0 ${px2Rem(20)};
    justify-content: space-between;
    align-items: center;
  }
`;

export const StyledNavLogoAndMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${px2Rem(72)};

  @media (min-width: ${NEW_BREAKPOINTS.mobileNav}px) {
    width: auto;
    height: ${px2Rem(78)};
  }
`;

export const StyledLogoWrapper = styled.div`
  svg g {
    fill: ${({ theme }) => theme?.navBar?.rayloPayLogoColor};
  }
`;

export const StyledMenuButton = styled.button`
  appearance: none;
  background: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  @media (min-width: ${NEW_BREAKPOINTS.mobileNav}px) {
    display: none;
  }
`;

export const StyledNavMenuWrapper = styled.div<{
  $isOpen: boolean;
  $isResizing: boolean;
}>`
  height: ${({ $isOpen }) => ($isOpen ? `${px2Rem(1076)}` : `${px2Rem(0)}`)};
  transition: ${({ $isResizing }) => ($isResizing ? 'none' : 'height 1s')};
  overflow: hidden;
  display: flex;
  justify-content: center;

  @media (min-width: ${NEW_BREAKPOINTS.mobileNav}px) {
    height: inherit;
    overflow: visible;
    height: ${px2Rem(78)};
    transition: none;
  }
`;

export const StyledNavMenu = styled.div<{ $isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${px2Rem(32)};
  opacity: ${({ $isOpen }) => ($isOpen ? '1' : '0')};
  pointer-events: ${({ $isOpen }) => ($isOpen ? 'all' : 'none')};
  transition: ${({ $isOpen }) =>
    $isOpen ? 'opacity 0.5s 1s' : 'opacity 0.125s 0s'};
  height: 80vh;
  padding-top: ${px2Rem(24)};

  @media (min-width: ${NEW_BREAKPOINTS.mobileNav}px) {
    height: inherit;
    flex-direction: row;
    opacity: 1;
    pointer-events: all;
    height: auto;
    padding-top: ${px2Rem(0)};
    gap: ${px2Rem(24)};
  }
`;

const sharedLinkStyles = css<{
  $color?: string;
  $colorHover?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${px2Rem(16)};
  cursor: pointer;
  color: ${({ theme, $color }) => $color ?? theme?.navBar?.linkColor};
  text-transform: uppercase;
  font-size: ${px2Rem(16)};
  letter-spacing: ${px2Rem(1)};

  &:hover {
    color: ${({ theme, $colorHover }) =>
      $colorHover ?? theme?.navBar?.hoverLinkColor};
    text-decoration: underline;
  }

  @media (min-width: ${NEW_BREAKPOINTS.mobileNav}px) {
    font-size: ${px2Rem(14)};
    letter-spacing: ${px2Rem(1.3)};
  }
`;

export const StyledExternalMenuLink = styled.a<{
  $color?: string;
  $colorHover?: string;
}>`
  ${sharedLinkStyles}
`;

export const StyledMenuLink = styled(Link)<{
  $color?: string;
  $colorHover?: string;
}>`
  ${sharedLinkStyles}
`;

export const StyledAboutLinkWrapper = styled.div`
  position: relative;

  @media (min-width: ${NEW_BREAKPOINTS.mobileNav}px) {
    display: flex;
    align-items: center;
    height: 100%;
  }
`;

export const StyledAboutLinkIcon = styled.span<{ $isActive: boolean }>`
  display: flex;
  cursor: pointer;
  transition: transform 0.5s;
  transform: ${({ $isActive }) =>
    $isActive ? 'rotate(-180deg)' : 'rotate(0)'};

  ${({ $isActive }) =>
    $isActive &&
    `
    & svg > path {
      fill: ${primaryColors.p01};
    }
  `}

  &:hover {
    color: ${({ theme }) => theme?.navBar?.linkColor};
    text-decoration: underline;

    & svg > path {
      fill: ${primaryColors.p01};
    }
  }
`;

export const StyledLoginLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  padding: ${px2Rem(20)} ${px2Rem(30)};
  justify-content: center;
  align-items: center;
  background-color: ${primaryColors.p01};

  @media (min-width: ${NEW_BREAKPOINTS.mobileNav}px) {
    height: 100%;
  }
`;

export const StyledAccountBanner = styled.div`
  background: ${primaryColors.b01};

  .upgrade-subscription__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: ${px2Rem(40)} ${px2Rem(24)};
    max-width: ${px2Rem(NEW_BREAKPOINTS.desktop)};

    @media (max-width: ${NEW_BREAKPOINTS.smallDesktop}px) {
      padding: ${px2Rem(24)} ${px2Rem(24)};
    }
  }

  p span {
    color: ${primaryColors.p01};
  }
`;

export const StyledBackButton = styled.button`
  text-decoration: none;
  display: flex;
  background: transparent;
  border: none;
  align-items: center;
  cursor: pointer;

  img {
    margin-right: ${px2Rem(10)};
  }
`;
