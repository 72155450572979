import styled from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';
import { IHeader } from './types';
import { CSS_FONT_WEIGHT } from '@raylo-tech/raylopay-ui';

const StyledHeaderContent = styled.span<IHeader>`
  font-weight: ${CSS_FONT_WEIGHT.vars.bold};
  margin: 0;
  ${({ color }) => color && `color: ${color};`}
  ${({ fontSize }) => fontSize && `font-size: ${px2Rem(fontSize)};`}
  & span {
    ${({ subColor }) => subColor && `color: ${subColor};`}
    ${({ subFontWeight }) =>
      subFontWeight === 'bold' && `font-weight: ${CSS_FONT_WEIGHT.vars.bold};`}
    ${({ subFontWeight }) =>
      subFontWeight === 'medium' && `font-weight: ${CSS_FONT_WEIGHT.vars.medium};`}
  }
`;

export { StyledHeaderContent };
