import NEW_BREAKPOINTS from '@/constants/breakpoints';
import { px2Rem } from './../../utils/px2Rem';
import styled from 'styled-components';

const StyledQuickLinks = styled.div`
  padding: ${px2Rem(24)};
  display: flex;
  justify-content: center;
`;

const StyledQuickLinksContainer = styled.div`
  max-width: ${px2Rem(1200)};
  display: flex;
  width: 100%;
  flex-direction: column;

  @media (min-width: ${NEW_BREAKPOINTS.tablet}px) {
    flex-direction: row;
    gap: ${px2Rem(16)};
  }
`;

const StyledQuickLinksGroup = styled.div<{ $tabletWidth: string }>`
  display: flex;
  width: 100%;
  flex-direction: column;

  @media (min-width: ${NEW_BREAKPOINTS.tablet}px) {
    width: ${({ $tabletWidth }) => $tabletWidth};
  }
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme?.quickLinks?.linkColor};
  font-size: ${px2Rem(14)};
`;

export { StyledQuickLinks, StyledQuickLinksContainer, StyledQuickLinksGroup, StyledLink };
