import { px2Rem } from '@/utils/px2Rem';
import styled from 'styled-components';

export const StyledProductsWrapper = styled.div<{ $loggedIn: boolean }>`
  ${({ $loggedIn }) => !$loggedIn && `
    padding-top: ${px2Rem(80)};
    
    @media (min-width: 900px) {
      padding-top: ${px2Rem(136)};
    }
  `}
`;
