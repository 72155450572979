import NEW_BREAKPOINTS from '@/constants/breakpoints';
import { px2Rem } from './../../utils/px2Rem';
import styled from 'styled-components';

const StyledFooter = styled.div`
  background-color: ${({ theme }) => theme?.footer?.backgroundColor};
  padding: 24px;
`;

const StyledFooterOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  @media (min-width: ${NEW_BREAKPOINTS.tablet}px) {
    height: ${px2Rem(524)};
  }
`;

const StyledFooterMainContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: ${px2Rem(1200)};
  height: 100%;
  flex-direction: column;

  @media (min-width: ${NEW_BREAKPOINTS.tablet}px) {
    flex-direction: row-reverse;
  }
`;

const StyledFooterBaselineContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${NEW_BREAKPOINTS.tablet}px) {
    flex-direction: row-reverse;
    justify-content: center;
  }
`;

const StyledFooterSocialContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${px2Rem(16)};
`;

const StyledFooterCopyrightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: ${px2Rem(16)};

  @media (min-width: ${NEW_BREAKPOINTS.tablet}px) {
    padding-top: 0;
  }
`;

const HeaderWrapper = styled.div`
  padding-top: ${px2Rem(16)};

  @media (min-width: ${NEW_BREAKPOINTS.tablet}px) {
    padding-top: ${px2Rem(80)};
  }

  p {
    color: ${({ theme }) => theme?.footer?.headerColor};
  }
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme?.footer?.linkColor};
  font-size: ${px2Rem(14)};
`;

const StyledLinkWrapper = styled.div`
  a {
    color: ${({ theme }) => theme?.footer?.linkColor};
    font-size: ${px2Rem(14)};
  }
`;

export {
  StyledFooter,
  StyledFooterOuterContainer,
  StyledFooterMainContainer,
  StyledFooterBaselineContainer,
  StyledFooterSocialContainer,
  StyledFooterCopyrightContainer,
  StyledLink,
  HeaderWrapper,
  StyledLinkWrapper
};
